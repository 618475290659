import { useMutation, useQuery } from "@apollo/client";
import apiClient, { ApiClient } from "../../../../lib/apiClient";
import TableComponent from "../../../components/elements/TableComponent";
import { UsersColumns } from "./columns";

const Users = () => {
  const { data, refetch } = useQuery<{
    users: ApiClient.User[];
  }>(apiClient.mutations.USERS);

  const [revokeUser] = useMutation<{
    revokeUser: { message: string };
  }>(apiClient.mutations.REVOKE_USER);

  const [enableUser] = useMutation<{
    revokeUser: { message: string };
  }>(apiClient.mutations.ENABLE_USER_ACCESS);

  const handleRevoke = (id: number) => {
    revokeUser({
      variables: {
        id: id,
      },
    })
      .then((response) => {
        refetch();
        console.log("User revoked successfully", response.data);
      })
      .catch((error) => {
        console.error("Error revoking user", error);
      });
  };

  const handleEnableAccess = (id: number) => {
    enableUser({
      variables: {
        id: id,
      },
    })
      .then((response) => {
        refetch();
        console.log("User Enabled successfully", response.data);
      })
      .catch((error) => {
        console.error("Error Enabling user", error);
      });
  };
  return (
    <div>
      <br />
      <TableComponent
        columns={UsersColumns(handleRevoke, handleEnableAccess)}
        data={data?.users || []}
        maxHeight={"calc(100vh - 200px)"}
      />
    </div>
  );
};
export default Users;
