import Layout from "../components/elements/Layout";
import { useQuery } from "@apollo/client";
import apiClient, { ApiClient } from "../../lib/apiClient";
import { Paper } from "@mui/material";
import InstallerForm from "../components/elements/InstallerForm";
import Table from "@mui/material/Table";
import {
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { DateTime } from "luxon";
import useSessionStore from "../../helpers/useLocalStore";

const Installers = () => {
  const { sessionStore } = useSessionStore();

  const { data: installersData, refetch: installersRefetch } =
    useQuery<ApiClient.GetInstallers>(apiClient.queries.GET_INSTALLERS, {
      pollInterval: 0,
    });

  // const navigateToBase64 = (version: {
  //   id: number;
  //   version: string;
  //   description: string;
  // }) => {
  //   var a = document.createElement("a"); //Create <a>
  //   // a.href = "data:image/png;base64," + version.file; //Image Base64 Goes here
  //   a.download = `${version.version}.dhu`; //File name Here
  //   a.click(); //Downloaded file
  // };

  const onCompleted = () => {
    installersRefetch();
  };

  return (
    <Layout page="Agent Versions">
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Id</TableCell>
              <TableCell>Created At</TableCell>
              <TableCell>Version</TableCell>
              <TableCell align="right">Description</TableCell>
              <TableCell align="right">
                <InstallerForm onCompleted={onCompleted} />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {installersData?.installers?.map((row, i: Number) => (
              <TableRow
                key={row.createdAt}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.id}
                </TableCell>
                <TableCell>
                  {DateTime.fromISO(row.createdAt).toFormat("LL/dd/y hh:mm a")}
                </TableCell>
                <TableCell component="th" scope="row">
                  {row.title}
                </TableCell>
                <TableCell align="right">{row.notes}</TableCell>

                <TableCell></TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Layout>
  );
};
export default Installers;
