import React from "react";
import Layout from "../../components/elements/Layout";
import CustomTabs from "../../components/elements/Tabs/Tabs";
import Invitations from "./Invitations";
import Users from "./Users";

const UserManagement = () => {
  const [selected, setSelected] = React.useState("users");
  const list = [
    { label: "Users", value: "users" },
    { label: "Invitations", value: "invitations" },
  ];
  return (
    <Layout page="User Management">
      <CustomTabs list={list} selected={selected} setSelected={setSelected} />

      {selected === "users" && <Users />}
      {selected === "invitations" && <Invitations />}
    </Layout>
  );
};
export default UserManagement;
