import { ApiClient } from "../../../../lib/apiClient";
import { Column } from "../../../components/elements/TableComponent/types";
import { Button } from "@mui/material";

export const UsersColumns = (
  handleRevoke: (id: number) => void,
  handleEnableAccess: (id: number) => void
) =>
  [
    {
      id: "email",
      label: "Email",
    },
    {
      id: "firstName",
      label: "First Name",
    },
    {
      id: "lastName",
      label: "Last Name",
    },
    {
      id: "isSuper",
      label: "Is Super",
      render: (_, row: any) => (row.isSuper ? "Super" : "Not Super"),
    },
    {
      id: "isActive",
      label: "Is Active",
      render: (_, row: any) => (row.isActive ? "Active" : "In Active"),
    },
    {
      id: "mobilePhone",
      label: "Phone Number",
    },

    {
      id: "userType",
      label: "User Type",
    },

    {
      id: "actions",
      label: "Actions",
      render: (_, row: ApiClient.User) => (
        <div>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              if (row?.isActive) handleRevoke(row.id);
              if (!row?.isActive) handleEnableAccess(row.id);
            }}
          >
            {row?.isActive ? "Revoke Access" : "Enable Access"}
          </Button>
        </div>
      ),
    },
  ] as Column[];
