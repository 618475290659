import { useNavigate } from "react-router-dom";
import Layout from "../../../components/elements/Layout";
import CompaniesPartial from "../../../components/partials/Companies";
import PracticesPartial from "../../../components/partials/Practices";
import { useEffect, useState } from "react";
import useSessionStore from "../../../../helpers/useLocalStore";
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Link,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import { useMutation, useQuery } from "@apollo/client";
import apiClient, { ApiClient } from "../../../../lib/apiClient";
import TextInputField from "../../../components/elements/FormFields/TextInputField/field";
import { PartnerHook } from "../../../components/partials/Webhooks/types";

const Home = () => {
  const navigate = useNavigate();
  const { sessionStore } = useSessionStore();

  const [activeStep, setActiveStep] = useState(0);
  const [webhookUrl, setWebhookUrl] = useState("");
  const [isLoading, setIsloading] = useState(false);
  const [generationMessage, setGenerationMessage] = useState("");

  const handleStep = (step: number) => setActiveStep(step);

  const [createPartnerHook] = useMutation<{
    createPartnerHook: PartnerHook;
  }>(apiClient.mutations.CREATE_PARTNER_HOOK);

  const [generateFakeData] = useMutation<{
    generateFakeData: { message: string };
  }>(apiClient.mutations.GENERATE_FAKE_DATA);

  const { data: practicesData } = useQuery<ApiClient.GetPractices>(
    apiClient.queries.GET_PRACTICES,
    {
      variables: {
        companyId: sessionStore?.partner?.companySandboxId,
      },
      fetchPolicy: "no-cache",
    }
  );

  const sandboxPractice = practicesData?.practices?.find((practice) => {
    return practice?.company?.id === sessionStore?.partner?.companySandboxId;
  });

  const { data: sitesData, refetch: refetchSites } =
    useQuery<ApiClient.GetSites>(apiClient.queries.GET_SITES, {
      fetchPolicy: "no-cache", // Disable caching for this query
    });

  const onCreatePartnerHook = async () => {
    try {
      return createPartnerHook({
        variables: {
          partnerHook: {
            name: "Practice Webhook",
            url: webhookUrl,
            practiceId: practicesData?.practices?.[0]?.id,
            partnerId: sessionStore?.partner?.id,
          },
        },
      });
    } catch (e) {
      console.log(e);
    }
  };

  const onGenerateFakeData = async () => {
    try {
      setIsloading(true);
      const response = await generateFakeData({
        variables: {
          siteId: sandboxPractice?.sites?.[0]?.id,
        },
      });

      setGenerationMessage(response?.data?.generateFakeData?.message || "");
    } catch (e) {
      console.log(e);
    } finally {
      setIsloading(false);
    }
  };

  const handleCreatePartnerHook = async () => {
    if (webhookUrl) {
      await onCreatePartnerHook();
    }
    handleStep(4);
  };

  const handleReadyToTryCta = async () => {
    navigate("/docs/graphql");
  };

  useEffect(() => {
    if (!sessionStore?.userType) {
    }
  }, [sessionStore?.userType, navigate]);

  return (
    <Layout page="Home">
      <Card style={{ padding: "2em" }}>
        <Typography variant="h5">Getting Started</Typography>
        <br />
        <Stepper
          activeStep={activeStep}
          orientation="vertical"
          style={{ textAlign: "left" }}
        >
          <Step key={"documentation"}>
            <StepLabel>Read Documentation</StepLabel>
            <StepContent>
              <Typography>
                Please look through our documentation so you can set up your
                sandbox environment. Both read and write is available.
                <br />
                <br />
                <Link href="/docs">Documentation</Link>
              </Typography>
              <br />

              <Box sx={{ mb: 2 }} display={"flex"} gap={1}>
                <Button
                  variant="contained"
                  onClick={handleStep.bind(null, 1)}
                  sx={{ mt: 1, mr: 1 }}
                >
                  Next
                </Button>
              </Box>
            </StepContent>
          </Step>
          <Step key={"apiKeys"}>
            <StepLabel>Save Your Sandbox Api Key</StepLabel>
            <StepContent>
              <Typography>
                You can use this key to access the sandbox environment.
              </Typography>
              <br />
              <Typography>
                <div style={{ overflow: "auto", wordWrap: "break-word" }}>
                  Headers
                  <br />
                  X-Partner-Api-Key:
                  <br />
                  {
                    sessionStore?.partner?.apiKeys?.find(
                      ({ isSandbox }) => isSandbox
                    )?.apiKey
                  }
                </div>
              </Typography>
              <br />
              <Box sx={{ mb: 2 }} display={"flex"} gap={1}>
                <Button
                  variant="outlined"
                  onClick={handleStep.bind(null, 0)}
                  sx={{ mt: 1, mr: 1 }}
                >
                  Back
                </Button>
                <Button
                  variant="contained"
                  onClick={handleStep.bind(null, 2)}
                  sx={{ mt: 1, mr: 1 }}
                >
                  Next
                </Button>
              </Box>
            </StepContent>
          </Step>
          <Step key={"practice"}>
            <StepLabel>Save Your Sandbox Practice Id</StepLabel>
            <StepContent>
              <Typography>
                The Practice Id below is required to connect.
              </Typography>
              <br />
              <Typography>
                <div style={{ overflow: "auto", wordWrap: "break-word" }}>
                  X-Practice-Id: <br />
                  {sandboxPractice?.remoteId}
                </div>
              </Typography>
              <br />
              <Box sx={{ mb: 2 }} display={"flex"} gap={1}>
                <Button
                  variant="outlined"
                  onClick={handleStep.bind(null, 1)}
                  sx={{ mt: 1, mr: 1 }}
                >
                  Back
                </Button>
                <Button
                  variant="contained"
                  onClick={handleStep.bind(null, 3)}
                  sx={{ mt: 1, mr: 1 }}
                >
                  Next
                </Button>
              </Box>
            </StepContent>
          </Step>
          <Step key={"webhook"}>
            <StepLabel>Save Your Webhook</StepLabel>
            <StepContent>
              <Typography>
                Enter the webhook URL below. This will be used to receive
                real-time notifications from our system.
              </Typography>
              <br />
              <TextInputField
                label="Webhook URL"
                value={webhookUrl}
                onChange={(e) => setWebhookUrl(e.target.value)}
                placeholder="Enter your webhook url"
              />
              <br />
              <Box sx={{ mb: 2 }} display={"flex"} gap={1}>
                <Button
                  variant="outlined"
                  onClick={() => handleStep(2)}
                  sx={{ mt: 1, mr: 1 }}
                >
                  Back
                </Button>
                <Button
                  variant="contained"
                  onClick={handleCreatePartnerHook}
                  sx={{ mt: 1, mr: 1 }}
                >
                  Next
                </Button>
              </Box>
            </StepContent>
          </Step>

          <Step key={"faker"}>
            <StepLabel>Generate Fake Data</StepLabel>
            <StepContent>
              <Typography>
                Generate fake data for your practice. This will help you test
                your integration and make sure everything is working as
                expected.
              </Typography>
              <br />

              {!generationMessage && (
                <Button
                  variant="outlined"
                  onClick={onGenerateFakeData}
                  sx={{ mt: 1, mr: 1 }}
                  startIcon={isLoading ? <CircularProgress /> : <></>}
                  disabled={isLoading}
                >
                  Generate Fake Data
                </Button>
              )}

              {generationMessage && (
                <Typography>
                  {generationMessage}
                  <br />
                  <Link href="/data-center">See the data in Data Center</Link>
                </Typography>
              )}

              <br />

              <Box sx={{ mb: 2 }} display={"flex"} gap={1}>
                <Button
                  variant="outlined"
                  onClick={() => handleStep(3)}
                  sx={{ mt: 1, mr: 1 }}
                >
                  Back
                </Button>
                <Button
                  variant="contained"
                  onClick={handleReadyToTryCta}
                  sx={{ mt: 1, mr: 1 }}
                >
                  Ready to try some requests?
                </Button>
              </Box>
            </StepContent>
          </Step>
        </Stepper>
      </Card>
    </Layout>
  );
};
export default Home;
