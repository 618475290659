import { Box, Tab } from "@mui/material";
import * as React from "react";

interface CustomTabsProps {
  list: { label: string; value: string; isDisabled?: boolean }[];
  selected: string;
  setSelected: (value: string) => void;
}

const CustomTabs = (props: CustomTabsProps) => {
  const { list, selected, setSelected } = props;

  return (
    <div>
      <Box sx={{ borderBottom: 1, borderColor: "divider", display: "flex" }}>
        <div>
          {list.map((item) => (
            <Tab
              key={item.value}
              label={item.label}
              value={item.value}
              disabled={item.isDisabled}
              sx={{
                textTransform: "none",
                fontSize: "20px",
                fontWeight: selected === item.value ? "bold" : "normal",
                color:
                  selected === item.value ? "primary.main" : "text.secondary",
                "&.Mui-disabled": {
                  color: "text.disabled",
                },
                "&:hover": {
                  color: item.isDisabled ? "text.disabled" : "primary.main",
                },
              }}
              onClick={() => {
                if (!item.isDisabled) {
                  setSelected(item.value);
                }
              }}
            />
          ))}
        </div>
      </Box>
    </div>
  );
};

export default CustomTabs;
