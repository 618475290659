import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useForm } from "react-hook-form";
import { Input, TextField } from "@mui/material";
import { fileReader } from "../../../../lib/fileReader";
import { useMutation } from "@apollo/client";
import apiClient, { ApiClient } from "../../../../lib/apiClient";
import AddIcon from "@mui/icons-material/Add";
import ResponsiveDrawer from "../Drawer";

type Inputs = {
  notes: string;
  title: string;
  bin: any;
};

type Props = {
  onCompleted: () => void;
  onCancel?: Function;
};

const InstallerForm = (props: Props) => {
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  const { register, handleSubmit } = useForm<Inputs>();

  const [
    createInstaller,
    { loading: createInstallersLoading, error: createInstallersError },
  ] = useMutation<ApiClient.GetInstallers["installers"][0]>(
    apiClient.mutations.ADD_INSTALLER
  );

  const submitForm = async (formData: any, e: any) => {
    // saveData(formData);
    e.preventDefault();

    console.log(formData);
    const data = await fileReader(formData.bin[0]);

    await createInstaller({
      variables: {
        installer: {
          notes: formData.notes,
          title: formData.title,
          bin: data,
        },
      },
    });

    setOpen(false);
    props.onCompleted();
  };

  const handleCancel: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();

    setOpen(false);

    props?.onCancel?.();
  };

  return (
    <>
      <Button onClick={toggleDrawer(true)} variant="outlined">
        <AddIcon />
      </Button>
      <ResponsiveDrawer
        open={open}
        onClose={toggleDrawer(false)}
        anchor="right"
      >
        <Box sx={{ width: "100%" }} role="presentation" p={4}>
          <form onSubmit={handleSubmit(submitForm)}>
            <br />
            <TextField
              defaultValue=""
              label="Title"
              {...register("title")}
              required={true}
              variant="outlined"
              style={{ width: "100%" }}
            />
            <br />
            <br />
            <TextField
              defaultValue=""
              label="Notes"
              {...register("notes")}
              required={true}
              variant="outlined"
              style={{ width: "100%" }}
            />
            <br />
            <br />
            <Input
              {...register("bin", {
                required: "Installer is required",
              })}
              type="file"
            />
            <br />
            <br />
            <Button type="submit" variant="contained">
              Save
            </Button>
            &nbsp; &nbsp; &nbsp;
            {/* {props?.onCancel && ( */}
            <Button variant="outlined" onClick={handleCancel}>
              Cancel
            </Button>
            {/* )} */}
          </form>
        </Box>
      </ResponsiveDrawer>
    </>
  );
};

export default InstallerForm;
