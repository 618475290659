import LandingPage from "./app/pages/LandingPage/LandingPage";

const Unauthenticated = (props: { status: string; errors: Error | null }) => {
  return (
    <>
      {props.status === "authenticating" ? (
        <div
          style={
            {
              textAlign: "center",
              paddingTop: "20%",
              backgroundColor: "#9d98b0",
              minHeight: "100vh",
              width: "100%",
            } as React.CSSProperties
          }
        >
          <h2>Loading</h2>
        </div>
      ) : props.status === "unauthorized" ? (
        <div
          style={
            {
              textAlign: "center",
              paddingTop: "20%",
              backgroundColor: "#9d98b0",
              minHeight: "100vh",
              width: "100%",
            } as React.CSSProperties
          }
        >
          <h2
            style={{
              color: "#c72828",
            }}
          >
            Unauthorized
          </h2>
        </div>
      ) : (
        // <Authenticate />
        <LandingPage />
      )}
    </>
  );
};

export default Unauthenticated;
