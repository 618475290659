import {
  Box,
  Button,
  Paper,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import useSession from "../../../../helpers/useLocalStore";
import { Logout } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import Layout from "../../../components/elements/Layout";
import { useEffect, useState } from "react";
import PracticeForm from "./PracticePairing";
import { useMutation, useQuery } from "@apollo/client";
import apiClient, { ApiClient } from "../../../../lib/apiClient";
import { MuiTelInput } from "mui-tel-input";
import { Controller, useForm } from "react-hook-form";
import CircularProgress from "@mui/material/CircularProgress";

const HomePractice = () => {
  const navigate = useNavigate();
  const { sessionStore } = useSession();
  const { control, handleSubmit } = useForm({
    defaultValues: {
      name: "",
      email: "",
      phoneNumber: "",
    },
  });

  const {
    data: currentUserResponse,
    loading: currentUserLoading,
    refetch,
  } = useQuery<{
    currentUser: ApiClient.GetCurrentUser;
  }>(apiClient.queries.GET_CURRENT_USER);
  const currentUser = currentUserResponse?.currentUser;

  const { data: partnerData } = useQuery<ApiClient.GetPartners>(
    apiClient.queries.GET_PARTNERS
  );

  const { data: practiceData } = useQuery<ApiClient.GetPractices>(
    apiClient.queries.GET_PRACTICES
  );

  const [createPractice, {}] = useMutation<{
    createPractice: ApiClient.GetPractice;
  }>(apiClient.mutations.CREATE_PRACTICE);

  let partner;
  const invitation = sessionStore?.invitations?.[0];

  for (let _partner of partnerData?.partners ?? []) {
    if (_partner.id === invitation?.partnerId) {
      partner = _partner;
    }
  }

  const submitHandler = async (data: any) => {
    const practice = await createPractice({
      variables: {
        practice: data,
      },
    });

    if (practice) {
      refetch();
      navigate("/onboarding/practice/pairing");
    }
  };

  useEffect(() => {
    if (currentUser?.practices?.[0]) {
      navigate("/onboarding/practice/pairing");
    }
  }, [currentUser]);

  return (
    <Layout isRaw={true}>
      <Paper style={{ display: "inline-block", padding: "20px" }}>
        <h1 style={{ textAlign: "center", color: "#fff", marginTop: "0px" }}>
          Welcome to Datahub
        </h1>
        <br />
        {currentUserLoading ? (
          <div>
            <CircularProgress />
          </div>
        ) : (
          <>
            <form onSubmit={handleSubmit(submitHandler)}>
              <div style={{ textAlign: "left" }}>
                <b>{partner?.name}</b>{" "}
                <span style={{ color: "#cfd2db" }}>is </span>
                <b>requesting access</b>
                <span style={{ color: "#cfd2db" }}>
                  &nbsp;to your Practice Information
                  <br />
                  Management System (PIMS) to enable their product.
                  <br />
                  To get started, please complete the onboarding process.
                </span>
              </div>
              <br />
              <br />
              <div style={{ textAlign: "left" }}>
                <Typography variant="h6">Practice Name</Typography>
              </div>
              <br />
              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <TextField
                    type="text"
                    {...field}
                    placeholder="Practice Name"
                    fullWidth
                  />
                )}
              />
              <br />
              <br />
              <br />
              <div style={{ textAlign: "left" }}>
                <Typography variant="h6">Practice Contact</Typography>
                May be different from the person who signed up.
              </div>
              <br />
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <TextField
                    type="text"
                    {...field}
                    placeholder="Email"
                    fullWidth
                  />
                )}
              />
              <br />
              <br />

              <Controller
                name="phoneNumber"
                control={control}
                render={({ field }) => (
                  <MuiTelInput
                    {...field}
                    defaultCountry="US"
                    forceCallingCode
                    fullWidth
                  />
                )}
              />
              <br />
              <br />
              <Button
                variant="contained"
                sx={{ marginTop: "20px" }}
                type="submit"
              >
                Next
              </Button>
            </form>
          </>
        )}
      </Paper>
    </Layout>
  );
};

export default HomePractice;
