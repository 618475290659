import { useMutation, useQuery } from "@apollo/client";
import {
  Box,
  Button,
  FormControl,
  TextField,
  Typography,
  Select,
  InputLabel,
  MenuItem,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import apiClient, { ApiClient } from "../../../../lib/apiClient";
import useSessionStore from "../../../../helpers/useLocalStore";
import Layout from "../../../components/elements/Layout";
import { useState } from "react";

interface InviteUser {
  email: string;
  userType: string;
  partnerId: string;
}

const Invite = () => {
  const { register, handleSubmit, getValues, watch } = useForm<InviteUser>({
    mode: "all",
  });

  const [inviteUser, { loading, error }] = useMutation<{
    inviteUser: ApiClient.InviteUser;
  }>(apiClient.mutations.INVITE_USER);

  const navigate = useNavigate();

  const { refetch } = useQuery<{
    invitations: ApiClient.UserInvitation[];
  }>(apiClient.mutations.INVITATIONS);

  const { data: partnerData } = useQuery<ApiClient.GetPartners>(
    apiClient.queries.GET_PARTNERS
  );

  watch();

  const handleCreatePartner = (params: InviteUser) => {
    inviteUser({
      variables: {
        invitation: {
          email: params?.email?.trim(),
          userType: params?.userType,
          partnerId: params?.partnerId,
        },
      },
    })
      .then(({ data }) => {
        refetch();
        navigate("/invitations");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const userType = watch("userType");

  const isValid = () => {
    const userType = getValues("userType");
    const email = getValues("email");
    const partnerId = getValues("partnerId");

    if (userType == "PARTNER_PENDING") {
      return !!email;
    }
    if (userType == "PRACTICE_PENDING") {
      return !!email && !!partnerId;
    }
  };

  return (
    <Layout page="Invite">
      <h1>Invite</h1>

      <Box sx={{ p: 2 }} style={{ border: "0px" }}>
        <form onSubmit={handleSubmit(handleCreatePartner)}>
          {/* register your input into the hook by invoking the "register" function */}
          {loading ? (
            <>Loading...</>
          ) : (
            <>
              {" "}
              {/* 
                Once Practice is selected show a select for partners so that an invite can be paired to a partner
                Adding a partner to an invitation will require adding a partnerId to the invitation
              */}
              {/* small (sm), medium (md), large (lg), etc. */}
              <div style={{ maxWidth: "500px", margin: "0 auto" }}>
                <FormControl fullWidth>
                  <InputLabel id="type-label">User Type</InputLabel>
                  <Select
                    id="type"
                    labelId="type-label"
                    defaultValue=""
                    {...register("userType")}
                    required={true}
                    label="Type"
                    fullWidth
                  >
                    <MenuItem value="PARTNER_PENDING">Partner</MenuItem>
                    <MenuItem value="PRACTICE_PENDING">Practice</MenuItem>
                  </Select>
                </FormControl>
                <br />
                <br />
                {userType == "PRACTICE_PENDING" && (
                  <>
                    <FormControl fullWidth>
                      <InputLabel id="type-label">Partner</InputLabel>
                      <Select
                        id="type"
                        labelId="type-label"
                        defaultValue=""
                        {...register("partnerId")}
                        required={true}
                        label="Type"
                        fullWidth
                      >
                        {partnerData?.partners?.map((partner) => (
                          <MenuItem key={partner.id} value={partner.id}>
                            {partner.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <br />
                    <br />
                  </>
                )}
                <TextField
                  id="email"
                  defaultValue=""
                  {...register("email")}
                  required={true}
                  label="Email"
                  fullWidth
                />
                {/* include validation with required or other standard HTML validation rules */}
                {/* errors will return when field validation fails  */}
                <br />
                <br />
                &nbsp;
                <Button type="submit" variant="contained" disabled={!isValid()}>
                  Invite
                </Button>
              </div>
            </>
          )}
        </form>
      </Box>
    </Layout>
  );
};

export default Invite;
