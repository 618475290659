import { useQuery } from "@apollo/client";

import {
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Button } from "@mui/material";
import apiClient, { ApiClient } from "../../../../lib/apiClient";
import { DateTime } from "luxon";

const Invitations = () => {
  const { data } = useQuery<{
    invitations: ApiClient.UserInvitation[];
  }>(apiClient.mutations.INVITATIONS);

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "end",
          alignItems: "center",
          margin: "20px 10px",
        }}
      >
        <Link href="/invitations/invite">
          <Button variant="contained" color="primary">
            Invite User
          </Button>
        </Link>
      </div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>id</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>User Type</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Invited At</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.invitations?.map((row) => (
              <TableRow
                key={row.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>{row.id}</TableCell>
                <TableCell>{row.email}</TableCell>
                <TableCell>{row.userType}</TableCell>
                <TableCell>{row.status}</TableCell>
                <TableCell>
                  {DateTime.fromMillis(parseInt(row.createdAt)).toFormat(
                    "yyyy-MM-dd HH:mm:ss"
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default Invitations;
