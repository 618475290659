import { useNavigate } from "react-router-dom";

import { useEffect, useState } from "react";
import Partner from "./Partner";
import Practice from "./Practice";
import useSessionStore from "../../../helpers/useLocalStore";
import { useQuery } from "@apollo/client";
import apiClient, { ApiClient } from "../../../lib/apiClient";
import Layout from "../../components/elements/Layout";

const Home = () => {
  const [state, setState] = useState({
    isLoading: true,
  });

  const { sessionStore } = useSessionStore();
  const navigate = useNavigate();

  const { data, loading } = useQuery<ApiClient.GetSites>(
    apiClient.queries.GET_SITES
  );

  let isReady = true; // Make it so the system forces onboarding
  if (data?.sites?.length !== 0) isReady = true;

  useEffect(() => {
    if (!loading) {
      console.log("sessionStore?.userType", sessionStore?.userType);

      if (!sessionStore?.userType) throw new Error("User type not found");
      if (sessionStore?.userType == "PARTNER_PENDING") {
        navigate("/onboarding/partner");
      }
      if (sessionStore?.userType == "PRACTICE_PENDING") {
        navigate("/onboarding/practice/basics");
      }

      if (sessionStore?.userType == "user" || !isReady) {
        navigate("/onboarding");
      } else {
        setState({ isLoading: false });
      }
    }
  }, [sessionStore?.userType, navigate, isReady, loading]);

  return state.isLoading ? (
    <div></div>
  ) : sessionStore?.userType === "PARTNER" ? (
    <Partner />
  ) : (
    <Layout page="Home"></Layout>
  );
  // <Practice />
};
export default Home;
