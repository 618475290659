const PairingCode = ({ pairingCode }: { pairingCode: string }) => {
  const characters = pairingCode?.split("") ?? [];
  const firstCharacters = characters.slice(0, 4);
  const secondCharacters = characters.slice(4, 8);

  return (
    <div>
      {firstCharacters.map((char, index) => (
        <span
          key={`0-${index}`}
          style={{
            fontSize: "30px",
            fontWeight: "bold",
            color: "#fff",
            marginRight: "5px",
          }}
        >
          {char}
        </span>
      ))}
      <span
        style={{
          fontSize: "30px",
          fontWeight: "bold",
          color: "#fff",
          marginRight: "5px",
        }}
      >
        -
      </span>
      {secondCharacters.map((char, index) => (
        <span
          key={`1-${index}`}
          style={{
            fontSize: "30px",
            fontWeight: "bold",
            color: "#fff",
            marginRight: "5px",
          }}
        >
          {char}
        </span>
      ))}
    </div>
  );
};

export default PairingCode;
