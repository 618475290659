import { createBrowserRouter } from "react-router-dom";
import Companies from "./pages/Companies";
import Practices from "./pages/Practices";
import Practice from "./pages/Practice";
import Sites from "./pages/Sites";
import Site from "./pages/Site";
import Home from "./pages/Home";
import AgentVersions from "./pages/AgentVersions";
import DataCenter from "./pages/DataCenter";
import DataCenterListing from "./pages/DataCenterListing";
import OnboardingPartner from "./pages/Onboarding/Partner";
import OnboardingPracticeBasics from "./pages/Onboarding/Practice/PracticeBasics";
import OnboardingPracticePairing from "./pages/Onboarding/Practice/PracticePairing";
import GraphiQL from "./pages/GraphiQL";
import Partners from "./pages/Partners";
import GraphiQLInternal from "./pages/GraphiQLInternal";
import Docs from "./pages/Docs";
import Swagger from "./pages/Swagger";
import Invite from "./pages/UserManagement/Invitations/Invite";
import Webhooks from "./pages/Webhooks";
import Installers from "./pages/Installers";
import UserManagement from "./pages/UserManagement";

const router = createBrowserRouter([
  {
    path: "/companies",
    Component: Companies,
  },
  {
    path: "/practices",
    Component: Practices,
  },
  {
    path: "/practices/:id",
    Component: Practice,
  },
  {
    path: "/sites",
    Component: Sites,
  },
  {
    path: "/sites/:id",
    Component: Site,
  },
  {
    path: "/data-center",
    Component: DataCenter,
  },
  {
    path: "/data-center-listing",
    Component: DataCenterListing,
  },
  {
    path: "/agent-versions",
    Component: AgentVersions,
  },
  {
    path: "/onboarding/partner",
    Component: OnboardingPartner,
  },
  {
    path: "/onboarding/practice/basics",
    Component: OnboardingPracticeBasics,
  },
  {
    path: "/onboarding/practice/pairing",
    Component: OnboardingPracticePairing,
  },
  {
    path: "/partners",
    Component: Partners,
  },
  {
    path: "/about",
    element: <div>About</div>,
  },
  {
    path: "/docs/swagger",
    Component: Swagger,
  },
  {
    path: "/docs/graphql",
    Component: GraphiQL,
  },
  {
    path: "/docs/internal/graphql",
    Component: GraphiQLInternal,
  },
  {
    path: "/installers",
    Component: Installers,
  },
  {
    path: "/invitations/invite",
    Component: Invite,
  },

  {
    path: "/users-management",
    Component: UserManagement,
  },
  {
    path: "/docs",
    Component: Docs,
  },
  {
    path: "*",
    Component: Home,
  },
  {
    path: "/webhooks",
    Component: Webhooks,
  },
]);

export default router;
