import { CircularProgress, Paper, Typography } from "@mui/material";
import { useState } from "react";
import Layout from "../../../components/elements/Layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import apiClient, { ApiClient } from "../../../../lib/apiClient";
import { useQuery } from "@apollo/client";
import PairingCode from "./PairingCode";

const PracticeBasics = () => {
  const [downloaded, setDownloaded] = useState(false);
  const { data: currentUserResponse, loading: currentUserLoading } = useQuery<{
    currentUser: ApiClient.GetCurrentUser;
  }>(apiClient.queries.GET_CURRENT_USER);
  const currentUser = currentUserResponse?.currentUser;

  const { data: installerResponse, loading: installerLoading } =
    useQuery<ApiClient.GetInstaller>(apiClient.queries.GET_INSTALLER);

  const handleCancel = () => {
    //if (props.onComplete) props.onComplete();
  };

  const handleContinue = () => {
    const base64String = installerResponse?.installer?.bin;
    if (!base64String) {
      console.error("No base64 string found");
      return;
    }

    const binary = atob(base64String); // decode base64 to binary
    const bytes = new Uint8Array(binary.length);
    for (let i = 0; i < binary.length; i++) {
      bytes[i] = binary.charCodeAt(i);
    }

    const blob = new Blob([bytes], { type: "application/octet-stream" });
    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = "datahub.msi"; // Set the desired file name
    a.click();

    URL.revokeObjectURL(url);

    setDownloaded(true);
  };

  const practice = currentUser?.practices?.[0];

  return (
    <Layout isRaw={true}>
      <Paper
        style={{
          display: "inline-block",
          padding: "20px 40px",
          maxWidth: "570px",
        }}
      >
        <h1 style={{ textAlign: "center", color: "#fff", marginTop: "0px" }}>
          Welcome to Datahub
        </h1>
        {!downloaded ? (
          <>
            <h3 style={{ textAlign: "center", color: "#fff" }}>
              Please download the installer.
            </h3>
            <div style={{ cursor: "pointer" }} onClick={handleContinue}>
              <FontAwesomeIcon
                icon={faDownload}
                style={{ color: "white" }}
                size="4x"
              />
            </div>
            <br />
            <br />
            <br />
          </>
        ) : (
          <>
            <h3 style={{ textAlign: "center", color: "#fff" }}>
              During your installation of the agent
              <br />
              you will be asked to enter the below pairing code.
            </h3>
            {practice?.pairingCode && (
              <PairingCode pairingCode={practice?.pairingCode} />
            )}
            <br />
            <Typography variant="h6">Waiting for Pairing</Typography>
            <br />
            <br />
            <CircularProgress />
            <br />
            <br />
            <br />
          </>
        )}
      </Paper>
    </Layout>
  );
};

export default PracticeBasics;
