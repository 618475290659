import apiClient, { ApiClient } from "../../lib/apiClient";
import Layout from "../components/elements/Layout";
import SitesPartial from "../components/partials/Sites";

type State = {
  drawerOpen: boolean;
  site?: ApiClient.GetSites["sites"][0];
};

const Sites = () => {
  return (
    <Layout page="Sites">
      <SitesPartial />
    </Layout>
  );
};

export default Sites;
