import React, { useEffect, useRef, useState } from "react";
import "./App.css";
import apiClient from "./lib/apiClient";
import { ApolloProvider } from "@apollo/client";
import { RouterProvider } from "react-router-dom";
import router from "./app/Router";
import { DataCenterContext } from "./app/components/partials/DataCenter/context";
import useSessionStore from "./helpers/useLocalStore";
import "swiper/css";
import Unauthenticated from "./Unauthenticated";
import config from "./config";

console.log(`
# Version: ${config.version} 
# Last Update: ${config.lastUpdate}
`);

let params = new URLSearchParams(document.location.search);

type LabelValue = {
  label: string;
  value: number;
};

function App() {
  const hasRun = useRef(false);

  const { sessionStore, setSessionStore } = useSessionStore();
  const [selectedCompany, setSelectedCompany] = useState<
    LabelValue | undefined
  >();
  const [selectedPractice, setSelectedPractice] = useState<
    LabelValue | undefined
  >();
  const [selectedSite, setSelectedSite] = useState<LabelValue | undefined>();
  let status = "";
  let token: string | null = "";
  let isAuthenticationUrl = false;
  if (window.location.pathname === "/session/authentication") {
    isAuthenticationUrl = true;
  }
  const [state, setState] = useState<any>({
    status: status,
    error: "",
  });

  useEffect(() => {
    if (hasRun.current) return;
    hasRun.current = true;

    if (isAuthenticationUrl) {
      const token = params.get("token");

      if (token) {
        setState({ status: "authenticating" });
        apiClient
          .authenticate(token)
          .then((response) => {
            if (response.data.authenticate) {
              setSessionStore(response.data.authenticate);
              window.location.href = "/";
            } else {
              setState({ status: "unauthorized" });
              setTimeout(() => {
                window.location.href = "/";
              }, 3000);
            }
          })
          .catch((error) => {
            setState({ status: "unauthorized", error });
            setTimeout(() => {
              window.location.href = "/";
            }, 3000);
          });
      }
    }
  }, []);

  return (
    <ApolloProvider client={apiClient.connection}>
      <DataCenterContext.Provider
        value={{
          selectedCompany,
          selectedPractice,
          selectedSite,
          setSelectedCompany,
          setSelectedPractice,
          setSelectedSite,
        }}
      >
        <div className="App">
          {!sessionStore?.email ? (
            <Unauthenticated status={state.status} errors={state.error} />
          ) : (
            <RouterProvider router={router} />
          )}
        </div>
      </DataCenterContext.Provider>
    </ApolloProvider>
  );
}

export default App;
