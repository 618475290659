import { useMutation } from "@apollo/client";
import {
  Box,
  Button,
  Card,
  CircularProgress,
  FormControl,
  InputLabel,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import apiClient, { ApiClient } from "../../../../lib/apiClient";
import useSessionStore from "../../../../helpers/useLocalStore";
import { useNavigate } from "react-router-dom";
import { MuiTelInput } from "mui-tel-input";
import { delay } from "../../../../helpers/delay";
import { useState } from "react";

interface PartnerFormInputs {
  name: string;
  description: string;
  website: string;
  phoneNumber: string;
}

interface PartnerCreateProps {
  onComplete?: Function;
  onBack?: Function;
}
const CreatePartner = (props: PartnerCreateProps) => {
  const { sessionStore, setSessionStore } = useSessionStore();

  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    control,
    // formState: { errors },
  } = useForm<PartnerFormInputs>();

  const [createPartner, { error }] = useMutation<{
    createPartner: ApiClient.GetPartners["partners"][0];
  }>(apiClient.mutations.CREATE_PARTNER);

  const navigate = useNavigate();

  const handleCreatePartner = async (partner: any) => {
    try {
      setLoading(true);
      const partnerData = {
        ...partner,
      };

      const { data } = await createPartner({
        variables: {
          partner: partnerData,
        },
      });

      setSessionStore({
        ...Object(sessionStore),
        partner: data?.createPartner,
        userType: "PARTNER",
      });

      // await delay(1500);
      navigate("/");
    } catch (error) {
      console.error("Error creating partner:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleBack: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e?.preventDefault();
    props?.onBack?.();
  };

  return (
    <Box sx={{ p: 2 }} style={{ border: "0px", maxWidth: "500px" }}>
      <form onSubmit={handleSubmit(handleCreatePartner)}>
        <>
          {error && error?.message}
          <FormControl>
            <Typography component="h4">
              Please enter your company name and description
            </Typography>
          </FormControl>
          <br />
          <br />
          <TextField
            id="name"
            defaultValue=""
            {...register("name")}
            required={true}
            label="Company Name"
            fullWidth
          />
          <br />
          <br />
          <TextField
            id="description"
            defaultValue=""
            {...register("description")}
            required={true}
            label="Description"
            fullWidth
          />
          <br />
          <br />
          <TextField
            id="website"
            defaultValue=""
            {...register("website")}
            required={true}
            label="Website"
            fullWidth
          />
          <br />
          <br />
          <Controller
            name="phoneNumber"
            control={control}
            render={({ field }) => (
              <MuiTelInput
                {...field}
                defaultCountry="US"
                forceCallingCode
                fullWidth
              />
            )}
          />
          <br />
          <br />
          {props.onBack && (
            <Button variant="contained" type="submit" onClick={handleBack}>
              Back
            </Button>
          )}
          &nbsp;
          <Button type="submit" variant="contained" disabled={loading}>
            {loading ? "Loading..." : "Create"}
          </Button>
        </>
      </form>
    </Box>
  );
};

export default CreatePartner;
